
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset-advanced'
export default createGlobalStyle`

  @font-face {
    font-family: "OpenSans";
    src: url("/fonts/OpenSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "OpenSans";
    src: url("/fonts/OpenSans-Light.ttf") format("truetype");
    font-weight: light;
    font-style: normal;
  }

  ${reset}

  html, body, #root {
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-family: ${props => props.theme.font.body};
    font-size: 14px;
    height: 100%;
    min-height: 100%;

    ::selection {
      background: ${props => props.theme.colors.highlight};
    }
  }

  .icon {
    stroke: ${props => props.theme.colors.text};
  }
`
