import { swiss } from '@theme-ui/presets'
import { merge } from 'theme-ui'

const theme = {
  breakpoints: [
    '40em'
  ],
  colors: {
    background: '#EBE9E4',
    border: '#DDDAD0',
    text: '#3D2C29',
    lightText: '#7E7867',
    content: {
      background: '#FFFFFF'
    },
    sidebar: {
      text: '#E83030',
      border: '#DDDAD0',
      link: '#3D2C29',
      activeLink: '#E83030'
    }
  },
  font: {
    body: 'OpenSans, "Segoe UI", Roboto, "Helvetica Neue", system-ui, -apple-system, BlinkMacSystemFont, sans-serif'
  },
  content: {
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 1px 0px;'
  }
}

export default merge(swiss, theme)
