import React, { FunctionComponent, ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import BaseStyles from './styles/BaseStyles'
import theme from './styles/theme'

interface Props {
  element: ReactNode
}

export const Boot: FunctionComponent<Props> = ({ element }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <BaseStyles />
      {element}
    </ThemeProvider>
  )
}
